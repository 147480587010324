<style lang="scss" scoped>
.bill {
	background-color: #f2f3f5;
	margin-bottom: 50px;
}
.nav_menu {
	float: right;
	font-size: 0.3rem;
	margin-left: -2rem;
}

.btns {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.sum_info {
	font-size: 0.24rem;
	margin-top: 0.2rem;
	padding: 0.3rem;
	background-color: #fff;
	.time {
		margin-bottom: 0.1rem;
	}
	.value {
		font-size: 0.3rem;
		font-weight: bold;
	}
	.r {
		margin-right: 0.4rem;
	}
}
.label {
	color: $font_color_info;
}

.goods_name {
	font-size: 0.22rem;
	color: $font_color_sec;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.all_assured_btn {
	color: $color_main;
}
// .list_header {
// border-top: 1px solid $font_color_sec;
// }
.bill_item .seq {
	color: #969799;
}
.goods_info {
	display: flex;
	align-items: center;
	.seq {
		width: 0.6rem;
	}
	.assureds {
		width: calc(100% - 0.6rem - 1.2rem - 1.2rem);
		padding: 0 0.1rem;
		box-sizing: border-box;
	}
	.money {
		width: 1.2rem;
		padding: 0.04rem;
		box-sizing: border-box;
		text-align: center;
	}
	.state {
		width: 1.2rem;
		text-align: right;
	}
	.assureds_header {
		text-align: center;
	}
	// .state_success {
	// color: $success;
	// }
	.state_error {
		color: $danger;
	}
}
.empty {
	background-color: #fff;
}

.text_center {
	text-align: center;
}

.detail_search_content {
	padding: 0.3rem 0;
	background-color: #f2f3f5;
	.btn {
		padding: 0.3rem 0.3rem 0;
	}
}

.assured_row {
	display: flex;
	align-items: center;
	.name {
		width: 30vw;
		text-align: left;
	}
}

::v-deep .van-dialog__content {
	max-height: 70vh;
	overflow: auto;
}

.btn_short {
	background-color: $color_back_main;
	border-color: #70b3f8;
}

.pay_btn_box {
	display: flex;
	justify-content: flex-end;
}

.balance_box {
	padding: 0.2rem;
	display: flex;
	align-items: center;
	.label {
		width: 40%;
	}
	.value {
		font-size: 0.6rem;
		font-weight: bold;
		text-align: center;
	}
}
.pay_info {
	padding: 0.2rem;
	display: flex;
	align-items: center;
	.label {
		width: 40%;
	}
	.value {
		font-size: 0.6rem;
		font-weight: bold;
	}
}

.ybx_lose {
	position: absolute;
	right: 1rem;
	top: 0.5rem;
	color: $font_color_info;
	font-size: 0.4rem;
}

.btn_batch_pay {
	display: flex;
	align-items: center;
	margin: 0.1rem;
	box-sizing: border-box;
}

/* 批量支付弹窗 */
.van_dialog {
	width: 96vw;
	.van_cell_dialog {
		padding: 10px 8px;

		.dialog_header {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.2rem 0;
			border-top: 1px solid #f2f6fc;
			border-bottom: 1px solid #f2f6fc;

			.batch_left {
				width: 1rem;
				display: flex;
				justify-content: center;
			}
			.batch_money {
				width: 1rem;
				text-align: center;
			}
			.batch_assureds {
				flex: 1;
				text-align: center;
			}
		}
		.wrap {
			height: 6rem;
			overflow: auto;

			.van_cell {
				padding: 0.2rem 0;

				.checkbox {
					width: 100%;
					height: 1rem;
					position: relative;

					&::v-deep .van-checkbox__icon .van-icon {
						position: absolute;
						top: 0.55rem;
						left: 0.3rem;
					}
					&::v-deep .van-checkbox__label {
						width: 100%;
						height: 100%;
						display: block;
						margin-left: 0;

						.bill_item_dialog {
							width: 100%;
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: space-around;
							align-items: center;

							.title {
								width: 100%;
								font-size: 0.22rem;
								color: $font_color_sec;
								display: flex;
								align-items: center;
								justify-content: space-around;

								.title_left {
									flex: 1;
									text-overflow: ellipsis;
									white-space: nowrap;
									overflow: hidden;
								}
								.title_right {
									margin-right: auto;
								}
							}

							.batch_info {
								width: 100%;
								display: flex;
								justify-content: center;
								align-items: center;

								.content_money {
									width: 1rem;
									text-align: center;
								}
								.content_assureds {
									flex: 1;
									text-align: center;
									margin-left: 1rem;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>

<template>
	<div class="bill">
		<!-- 查询条件 -->
		<van-button type="default" block @click="deatailSearchPop = true">
			<van-icon class="iconfont ybx_more" color="#1989fa" />
		</van-button>

		<!-- 快捷查询按钮 -->
		<div class="btns">
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(30)">最近半小时</van-button>
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(60)">最近1小时</van-button>
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(120)">最近2小时</van-button>
			<van-button type="info" size="normal" class="btn_short" @click="shortSearch(0)">今天</van-button>
		</div>

		<!-- 批量支付按钮 -->
		<div class="btn_batch_pay">
			<van-button v-if="showBatchPay" type="info" block size="normal" class="btn_short" @click="handleBatchPay">批量支付</van-button>
		</div>

		<!-- 统计信息 -->
		<div class="sum_info">
			<div class="label time" v-text="`${startTime ? startTime : '~'} 至 ${endTime ? endTime : '~'}`"></div>
			<span class="label">已支付订单数量：</span>
			<span class="value r" v-text="hasPayBillNum"></span>
			<span class="label">已支付被保险人数量：</span>
			<span class="value" v-text="hasPayAssuredNum"></span>
		</div>

		<!-- 批量支付弹窗 -->
		<van-dialog class="van_dialog" v-model="batchPayDialog" title="批量支付" show-cancel-button :confirmButtonText="confirmButtonText" @confirm="handleOpenBatchPay" @cancel="handleBatchPayCancel" :before-close="handleBeforeClose" confirmButtonColor="#2594EF">
			<van-cell border class="van_cell_dialog">
				<div class="dialog_header">
					<span class="batch_left"><van-checkbox class="batch_check_all" v-model="checkAll" shape="square" @change="handleCheckAll" ref="checkboxAll" /></span>
					<span class="batch_assureds">被保人</span>
					<span class="batch_money">金额</span>
				</div>
				<van-checkbox-group class="wrap" v-model="checkedBill" ref="checkboxGroup" @change="handleCheckedChange">
					<van-cell class="van_cell" v-for="(item, index) in batchPayBillList" :key="index">
						<van-checkbox class="checkbox" :disabled="disabledList.includes(index)" :name="item" shape="square" ref="checkbox">
							<div class="bill_item_dialog">
								<div class="title">
									<span class="title_left" v-text="`${item.proName} - ${item.comboName}`"></span>
									<span class="title_right" v-text="item.createTime"></span>
								</div>
								<div class="batch_info">
									<span class="content_assureds">
										<span class="assured_name" v-text="assuredNameFormatter(item, 'insBillAssuredList')"></span>
										<span class="all_assured_btn" @click.stop="showAllAssured(item, 'insBillAssuredList')" v-text="` (${item.assureNum > showNameNum ? '等' : ''}${item.assureNum}人)`"></span>
									</span>
									<span class="content_money" v-text="item.premium"></span>
								</div>
							</div>
						</van-checkbox>
					</van-cell>
				</van-checkbox-group>
			</van-cell>
		</van-dialog>

		<van-cell border>
			<div class="list_header goods_info">
				<span class="seq seq_header">序号</span>
				<span class="assureds assureds_header">被保人</span>
				<span class="money money_header">金额</span>
				<span class="state state_header">支付状态</span>
			</div>
		</van-cell>
		<van-empty v-show="!list.length" class="empty" description="暂无订单" />
		<van-list v-model="loading" :finished="finished" :finished-text="list.length ? '没有更多了' : ''" @load="onLoad">
			<van-cell v-for="(item, index) in list" :key="index">
				<div class="bill_item">
					<div class="goods_name">
						<span v-text="`${item.proName} - ${item.comboName}`"></span>
						<span v-text="item.createTime"></span>
					</div>
					<div class="goods_info">
						<span class="seq" v-text="index + 1"></span>
						<span class="assureds">
							<span class="assured_name" v-text="assuredNameFormatter(item)"></span>
							<span class="all_assured_btn" @click="showAllAssured(item)" v-text="` (${item.assureNum > showNameNum ? '等' : ''}${item.assureNum}人)`"></span>
						</span>
						<span class="money" v-text="item.premium"></span>
						<span class="state" :class="{ state_success: item.payState == 1, state_error: item.payState == 0 }" v-text="payStateDict[String(item.payState)]"></span>
					</div>
				</div>
				<div class="pay_btn_box">
					<template v-if="item.payState == 0 && item.billState == 2">
						<van-button type="info" size="small" class="btn_short" @click="openPay(item)">支付</van-button>
					</template>

					<!-- 订单状态为9时可以更新保障期限，这里是有疏漏的，因为导致订单状态为9的情况不止是超时未支付一种情况。此处暂时这样修改，待以后完善订单信息 -->
					<!-- 华泰的产品，交互的时候先核保，支付的时候只传核保返回的id，所以暂时无法更新保障期限 -->
					<template v-if="item.billState == 9 && item.payState == 0 && !item.insurerName.includes('华泰')">
						<van-button type="info" size="small" class="btn_short" @click="update(item)">更新并支付</van-button>
					</template>
				</div>
				<span v-show="item.billState == 9" class="icon_lose ybx_lose iconfont"></span>
			</van-cell>
		</van-list>

		<!-- 被保险人弹窗 -->
		<van-dialog v-model="assuredDialog" className="assured_dialog" title="被保险人" confirmButtonText="关闭" confirmButtonColor="#2594EF">
			<van-cell v-for="(as, index) in assuredList" :key="index" value-class="text_center">
				<div class="assured_row">
					<span class="name" v-text="as.name"></span>
					<span class="mobile" v-text="as.mobile"></span>
				</div>
			</van-cell>
		</van-dialog>

		<!-- 支付 -->
		<van-dialog v-model="payDialog" className="assured_dialog" title="余额支付" :showCancelButton="true" confirmButtonText="支付" @confirm="pay" @cancel="payCancel" confirmButtonColor="#2594EF">
			<div class="balance_box">
				<span class="label">账户余额：</span>
				<span class="value" v-text="balance"></span>
			</div>
			<div class="pay_info">
				<span class="label">支付金额：</span>
				<span class="value" v-text="payBillInfo.premium || totalPremium"></span>
			</div>
		</van-dialog>

		<!-- 筛选条件 -->
		<van-popup v-model="deatailSearchPop" position="top">
			<div class="detail_search_content">
				<van-field v-model="startTime" readonly label="开始时间" @click="checkStartTime" placeholder="请选择开始时间">
					<template #button>
						<van-button size="small" type="info" class="btn_short" @click.stop="startTime = ''">清空</van-button>
					</template>
				</van-field>
				<van-field v-model="endTime" readonly label="结束时间" @click="checkEndTime" placeholder="请选择结束时间">
					<template #button>
						<van-button size="small" type="info" class="btn_short" @click.stop="endTime = ''">清空</van-button>
					</template>
				</van-field>
				<van-field v-model="assureInfo" label="查询条件" placeholder="姓名/证件号码/手机号码" @click="openPop()"></van-field>
				<van-field v-model="payState" label="支付状态" is-link readonly placeholder="请选择支付状态" @click="openPop('payStatePop')"></van-field>
				<van-field v-model="user" label="会员" is-link readonly placeholder="请选择子账户或部门账户" @click="openPop('userPop')"></van-field>

				<div class="btn">
					<van-button type="info" class="btn_short" block @click="detailSearch">查询</van-button>
					<!-- <van-button type="default" class="" block style="margin-top: 10px;" @click="logout">退出登录</van-button> -->
				</div>
			</div>
		</van-popup>

		<!-- 时间选择 -->
		<van-popup v-model="timePop" round position="bottom" :duration="duration">
			<van-datetime-picker v-model="time" type="datetime" :title="timePickerTitle" @cancel="timePop = false" @confirm="timeConfirm" />
		</van-popup>

		<!-- 支付状态选择 -->
		<van-popup v-model="payStatePop" round position="bottom" :duration="duration">
			<van-picker title="支付状态" show-toolbar :columns="payStateList" value-key="name" @confirm="payStateConfirm" @cancel="payStatePop = false" />
		</van-popup>

		<!-- 下级账户选择 -->
		<van-popup v-model="userPop" round position="bottom" :duration="duration">
			<van-picker title="会员" show-toolbar :columns="childAccountList" visible-item-count="5" value-key="userName" @confirm="userConfirm" @cancel="userPop = false" />
		</van-popup>
	</div>
</template>

<script>
import { Cell, Button, Loading, List, Empty, Dialog, Icon, Popup, Field, DatetimePicker, Picker, Toast, Checkbox, CheckboxGroup, CellGroup } from 'vant';
import { http_getBills, http_getSubAccount, http_getAccountInfo, http_payByBalance, http_getBillsContainUserInfo, http_batchUpdateInsurancePeriod } from '@/request/billSearch';
import { updateInsureRange } from '@/request/api';
import { payState, payStateDict } from '@/config/fixedParams';

export default {
	name: 'bill', // 查单系统订单页面
	components: {
		[Button.name]: Button,
		[Loading.name]: Loading,
		[List.name]: List,
		[Cell.name]: Cell,
		[Empty.name]: Empty,
		[Icon.name]: Icon,
		[Popup.name]: Popup,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Picker.name]: Picker,
		[Dialog.Component.name]: Dialog.Component,
		[Checkbox.name]: Checkbox,
		[CheckboxGroup.name]: CheckboxGroup,
		[CellGroup.name]: CellGroup,
	},
	data() {
		return {
			startTime: '2021-08-01 00:00:00',
			endTime: '2021-09-10 20:20:16',
			assureInfo: '', // 查询条件
			payState: '', // 支付状态
			user: '', // 子账号或部门账号名称

			payStateChecked: null,
			userChecked: null,

			hasPayBillNum: 0,
			hasPayAssuredNum: 0,
			list: [],

			loading: false,
			finished: false,
			total: 0,
			currentPage: -1,
			showNameNum: 6, // 要显示的被保险人名字的数量

			payStateDict: payStateDict,

			assuredDialog: false,
			assuredList: [],

			deatailSearchPop: false,
			timePop: false,
			time: '',
			timePickerTitle: '选择开始时间',

			payStatePop: false,

			userPop: false,
			childAccountList: [],

			duration: 0.3, // pop动画时长

			isShortSearch: true, // 是否为快捷查询

			payDialog: false,
			payBillInfo: {},

			balance: 0,

			batchPayBillList: [], // 可批量支付的订单
			batchPayDialog: false, // 批量支付弹窗
			checkAll: false, // 全选全不选切换
			checkedBill: [], // 选中的订单
			totalPremium: 0, // 支付总金额
			billIdList: [], // 批量支付的订单id集合
			confirmButtonText: '支付', // 更新并支付文字
			disabledList: [], // 需要禁用的选项
			saveItem: [], // 相同的选中的选项
		};
	},
	computed: {
		payStateList() {
			return [{ name: '请选择支付状态', value: '' }, ...payState];
		},
		// 判断是否显示批量支付按钮
		showBatchPay() {
			const startTime = this.startTime.replace(/-/g, '/');
			const startTime_unix = Date.parse(startTime);
			const nowDate = this.$base.getDate().replace(/-/g, '/');
			const nowDate_unix = Date.parse(nowDate);
			if (startTime_unix >= nowDate_unix) {
				return true;
			} else {
				return false;
			}
		},
	},
	created() {
		this.startTime = `${this.$base.getDate()} 00:00:00`;
		this.endTime = this.$base.getDateTime();

		this.setCookie().then(this.getSubAccount);
	},
	methods: {
		// 跳转微信小程序
		jumpToWX(err) {
			if (err.code === 9) {
				// eslint-disable-next-line no-undef
				wx.miniProgram.redirectTo({ url: `../login/index?clearCookie=1` });
			}
		},

		// 设置cookie
		setCookie() {
			return new Promise(resolve => {
				const cookie = this.getQueryString('cookie');
				if (cookie) {
					const cookies = cookie.split(';');
					cookies.forEach(item => {
						document.cookie = `${item};path=/;`;
					});
				} else {
					if (document.cookie.length) {
						const cookies = document.cookie.split(';');
						cookies.forEach(item => {
							const name = item.split('=')[0];
							document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
						});
					}
				}
				setTimeout(() => {
					resolve();
				}, 20);
			});
		},

		// 获取微信传递过来的cookie
		getQueryString(name) {
			const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
			const r = location.search.substr(1).match(reg);
			if (r !== null) {
				return decodeURIComponent(r[2]);
			}
			return null;
		},

		// 批量支付
		handleBatchPay() {
			this.batchPaySearch();
		},
		// 批量支付弹窗支付按钮
		async handleOpenBatchPay() {
			if (!this.checkedBill.length) {
				Toast.fail('请选择需要支付的订单');
				return;
			}
			// 批量支付的订单的id
			this.billIdList = this.checkedBill.map(item => item.billId);
			// 更新保障期限
			if (this.confirmButtonText === '更新并支付') await this.batchPayUpdate();
			// 计算批量支付的总金额
			this.totalPremium = this.checkedBill.reduce((per, cur) => (per += cur.premium), 0);

			// 打开支付弹窗
			this.openBatchPay();
		},
		// 批量支付弹窗取消按钮
		handleBatchPayCancel() {
			this.checkedBill = [];
			this.disabledList = [];
		},
		// 批量支付弹窗关闭时的回调
		handleBeforeClose(action, done) {
			if (action === 'confirm') {
				done(false);
			} else {
				done();
			}
		},
		// 全选切换按钮
		handleCheckAll(checked) {
			if (checked) {
				// 当有禁用选项时点击全选按钮
				if (this.disabledList.length) {
					this.$refs.checkbox.forEach((item, index) => {
						if (!item.checked && !item.disabled && !this.disabledList.includes(index)) {
							this.checkedBill.push(item.name);
						}
					});
				} else {
					const certificateList = [];
					for (let i = 0; i < this.batchPayBillList.length; i++) {
						for (let j = 0; j < this.batchPayBillList[i].insBillAssuredList.length; j++) {
							if (!certificateList.includes(this.batchPayBillList[i].insBillAssuredList[j].certificateContent)) {
								certificateList.push(this.batchPayBillList[i].insBillAssuredList[j].certificateContent);
							} else {
								this.disabledList.push(i);
							}
						}
					}
					this.checkedBill = this.batchPayBillList.filter((item, index) => !this.disabledList.includes(index));
				}
			} else {
				this.checkedBill = [];
				this.disabledList = [];
			}
		},
		// 勾选变化时处理
		handleCheckedChange(names) {
			// 判断是否有未更新的订单, 更改confirmText
			if (names.length) {
				names.forEach(item => {
					if (item.billState == 9) {
						this.confirmButtonText = '更新并支付';
					}
				});
			} else {
				this.confirmButtonText = '支付';
			}

			// 判断是否禁用掉已经存在的被保人的订单
			const disabledList = [];
			if (names.length) {
				// 暂时根据name判断, 后续更改
				const assuredList = names.map(item => item.insBillAssuredList).flat(2);
				const certificateList = [...new Set(assuredList.map(item => item.certificateContent))];
				const checkboxRef = this.$refs.checkbox;
				for (let i = 0; i < this.batchPayBillList.length; i++) {
					for (let j = 0; j < this.batchPayBillList[i].insBillAssuredList.length; j++) {
						if (certificateList.includes(this.batchPayBillList[i].insBillAssuredList[j].certificateContent) && !checkboxRef[i].checked) {
							disabledList.push(i);
						}
					}
				}
				this.disabledList = [...new Set(disabledList)];
			} else {
				this.disabledList = [];
			}

			// 判断有无勾选全部订单, 控制全选按钮
			if (this.batchPayBillList.length === names.length + this.disabledList.length) {
				this.checkAll = true;
			}

			if (!names.length) {
				this.checkAll = false;
			}
		},

		// 取消支付
		payCancel() {
			this.payBillInfo = {};
			this.billIdList = [];
		},
		// 支付
		pay() {
			Toast({
				type: 'loading',
				message: '支付中...',
				forbidClick: true,
				duration: 0,
			});
			if (this.billIdList.length) {
				// 批量支付
				this.batchPay();
			} else {
				http_payByBalance({ billId: this.payBillInfo.billId })
					.then(() => {
						Toast.clear();
						Toast.success('支付成功');
						this.shortSearch(0);
					})
					.catch(err => {
						this.jumpToWX(err);
					});
			}
		},
		// 更新批量支付订单的保障期限
		async batchPayUpdate() {
			const arr = [];
			// 需要更改的地方
			this.checkedBill.forEach(bill => {
				if (bill.billState == 9 && bill.payState == 0) {
					// immediateEndTimeType 即时起保终保时间类型 0:当天24小时 1:合计24小时
					let isFullDay = bill.immediateEndTimeType !== '0';

					let newTime = this.getUpdateTime(bill.immediatelyFlag, this.getProtectDays(bill.enableDate, bill.disenableDate), isFullDay);
					const updateParams = Object.assign({ id: bill.billId }, newTime);
					arr.push(updateParams);
				}
			});
			// 提交更新保障期限的时间
			// 后续改为批量更新保障期限的接口
			await this.batchSubmitUpdateTime(arr);

			this.confirmButtonText = '支付';
		},
		// 批量支付
		batchPay() {
			http_payByBalance({ billId: this.billIdList.join(',') }).then(() => {
				Toast.clear();
				Toast.success('支付成功');
				this.shortSearch(0);
				this.batchPayDialog = false;
				this.checkAll = false;
			});
		},

		// 打开支付
		openPay(billInfo) {
			this.payBillInfo = billInfo;
			this.getBalance().then(balance => {
				this.balance = balance;
				this.payDialog = true;
			});
		},
		// 打开支付弹窗
		openBatchPay() {
			this.getBalance().then(balance => {
				this.balance = balance;
				this.payDialog = true;
			});
		},

		// 获取账户余额
		getBalance() {
			return new Promise(resolve => {
				http_getAccountInfo()
					.then(res => {
						this.jumpToWX(res);
						resolve(res.fnAccount.balance);
					})
					.catch(err => {
						this.jumpToWX(err);
					});
			});
		},

		// 打开pop
		openPop(notKey) {
			if (!notKey) {
				this.duration = 0;
			} else {
				this.duration = 0.3;
			}

			let keys = ['timePop', 'payStatePop', 'userPop'];
			keys.forEach(key => {
				this[key] = notKey === key;
			});
		},
		// 选择下级账户
		userConfirm(v) {
			this.userChecked = v;
			this.user = v.userName;
			this.userPop = false;
		},

		// 选择支付状态
		payStateConfirm(v) {
			this.payStateChecked = v;
			this.payState = v.name;
			this.payStatePop = false;
		},

		// 选择开始时间
		checkStartTime() {
			this.time = this.startTime ? new Date(this.startTime.replace(/-/g, '/')) : new Date();
			this.timePickerTitle = '选择开始时间';
			this.openPop('timePop');
		},

		// 选择结束时间
		checkEndTime() {
			this.time = this.endTime ? new Date(this.endTime.replace(/-/g, '/')) : new Date();
			this.timePickerTitle = '选择结束时间';
			this.openPop('timePop');
		},

		// 选择时间
		timeConfirm(value) {
			if (this.timePickerTitle.includes('开始')) {
				this.startTime = this.$base.getDateTime(value);
			} else {
				this.endTime = this.$base.getDateTime(value);
			}
			this.timePop = false;
		},

		detailSearch() {
			this.openPop();
			this.deatailSearchPop = false;
			this.isShortSearch = false;
			this.initList();
			this.search();
		},

		// 快捷查询
		shortSearch(minutes) {
			if (minutes) {
				this.startTime = this.$base.getDateTime(Date.now() - minutes * 60 * 1000);
			} else {
				this.startTime = `${this.$base.getDate()} 00:00:00`;
			}
			this.endTime = this.$base.getDateTime();
			this.isShortSearch = true;

			this.initList();
			this.search();
		},

		// 初始化数据
		initList() {
			this.list = [];
			this.currentPage = -1;
			this.finished = false;
		},

		onLoad() {
			if ((!this.list.length && !this.finished) || this.list.length < this.total) {
				this.search();
			} else {
				this.finished = true;
			}
		},

		// 查询数据
		search() {
			this.currentPage++;
			let send = {
				limit: 10,
				page: this.currentPage,
				startTime: this.startTime,
				endTime: this.endTime,
				assureInfo: '',
				payState: '',
				userId: '',
			};
			if (!this.isShortSearch) {
				send.assureInfo = this.assureInfo;
				send.payState = this.payStateChecked ? this.payStateChecked.value : '';
				send.userId = this.userChecked ? this.userChecked.userId : '';
			}
			this.loading = true;
			http_getBills(send)
				.then(res => {
					console.log(res, 'search');
					this.total = res.total;
					this.hasPayBillNum = res.billSum.billNum;
					this.hasPayAssuredNum = res.billSum.assureNum;
					let list = res.rows.map(item => {
						item.assuredList = String(item.assureInfo)
							.split(',')
							.map(as => {
								let asList = as.split('&');
								return { name: asList[0], mobile: asList.length > 1 ? asList[1] : '' };
							});
						return item;
					});
					this.list.push(...list);

					if (res.total == 0) {
						this.finished = true;
					}
				})
				.catch(err => {
					this.jumpToWX(err);
					this.finished = true;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		// 查询批量支付数据
		batchPaySearch() {
			let send = {
				startTime: this.startTime,
				endTime: this.endTime,
				assureInfo: '',
				payState: 0,
				userId: '',
			};
			if (!this.isShortSearch) {
				send.assureInfo = this.assureInfo;
				send.payState = this.payStateChecked ? this.payStateChecked.value : '';
				send.userId = this.userChecked ? this.userChecked.userId : '';
			}
			this.loading = true;
			http_getBillsContainUserInfo(send)
				.then(res => {
					console.log(res, 'batchSearch');
					let list = res.rows;

					// 过滤未支付已失效的华泰的订单与超过购买时间的订单
					this.batchPayBillList = list.filter(item => {
						return (item.billState == 2 && item.payState == 0) || (item.billState == 9 && item.payState == 0 && !item.insurerName.includes('华泰')) || (item.billState == 9 && item.payState == 0 && new Date().getHours() < item.immediatelyTimeLimit);
					});

					// 判断是否可批量支付
					if (this.batchPayBillList.length) {
						this.batchPayDialog = true;
					} else {
						Toast.fail('没有可以批量支付的订单');
					}
				})
				.catch(err => {
					this.jumpToWX(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		// 查询子账号
		getSubAccount() {
			http_getSubAccount()
				.then(res => {
					this.childAccountList = [{ userId: '', userName: '请选择子账户或部门账户' }, ...res.rows];
				})
				.catch(err => {
					this.jumpToWX(err);
				});
		},

		// 被保险人名称格式化
		assuredNameFormatter(info, type = 'assuredList') {
			if (info.assureNum <= this.showNameNum) {
				return info[type].map(item => item.name).join(',');
			} else {
				return info[type]
					.slice(0, this.showNameNum)
					.map(item => item.name)
					.join(',');
			}
		},

		// 显示所有被保险人
		showAllAssured(info, fieldName = 'assuredList') {
			this.assuredList = info[fieldName];
			this.assuredDialog = true;
		},

		// 更新保障期限
		update(bill) {
			// 检查当前是否已经禁止购买保险
			if (new Date().getHours() >= bill.immediatelyTimeLimit) {
				Toast.fail('当天已经无法购买该保险，请明天再试');
				return;
			}
			// immediateEndTimeType 即时起保终保时间类型 0:当天24小时 1:合计24小时
			let isFullDay = bill.immediateEndTimeType !== '0';

			let newTime = this.getUpdateTime(bill.immediatelyFlag, this.getProtectDays(bill.enableDate, bill.disenableDate), isFullDay);
			let updateParams = Object.assign({ id: bill.billId }, newTime);
			this.submitUpdateTime(updateParams).then(() => {
				this.openPay(bill);
			});
		},

		// 提交更新的时间
		submitUpdateTime({ id, start, end }) {
			return new Promise(resolve => {
				let send = {
					billId: id,
					enableDate: start,
					disEnableDate: end,
				};
				Toast({
					type: 'loading',
					message: '更新起保时间...',
					forbidClick: true,
					duration: 0,
				});
				updateInsureRange(send)
					.then(() => {
						Toast.clear();
						resolve();
						// 再查询一次数据
						this.shortSearch(0);
					})
					.catch(err => {
						this.jumpToWX(err);
					});
			});
		},
		// 批量提交更新的时间
		batchSubmitUpdateTime(arr) {
			return new Promise(resolve => {
				const send = arr.map(item => {
					return {
						billId: item.id,
						enableDate: item.start,
						disEnableDate: item.end,
					};
				});
				Toast({
					type: 'loading',
					message: '更新起保时间...',
					forbidClick: true,
					duration: 0,
				});
				http_batchUpdateInsurancePeriod(send)
					.then(() => {
						Toast.clear();
						resolve();
						// 再查询一次数据
						this.shortSearch(0);
						this.batchPaySearch();
					})
					.catch(err => {
						this.jumpToWX(err);
					});
			});
		},

		// 计算保障天数
		getProtectDays(start, end) {
			return Math.ceil((Number(end) - Number(start)) / 24 / 60 / 60 / 1000);
		},

		// 计算更新时间 type-是否即时起保  days-保障期限（天）  isFullDay-即时起保保障期限是否要合计24小时
		getUpdateTime(type, days) {
			let result = {
				start: '',
				end: '',
			};
			if (type) {
				// 即时起保，起保时间是10分钟之后
				result.start = this.$base.getDateTime(Date.now() + 10 * 60 * 1000);
				// if (isFullDay) {
				//     // 合计24小时
				//     result.end = this.$base.getDateTime(Date.now() + 10 * 60 * 1000 + days * 24 * 60 * 60 * 1000 - 1000);
				// }else {
				//     result.end = this.$base.submitDateFormatter(Date.now() + 10 * 60 * 1000 + (days - 1) * 24 * 60 * 60 * 1000, 2)
				// }
				result.end = this.$base.submitDateFormatter(Date.now() + 10 * 60 * 1000 + (days - 1) * 24 * 60 * 60 * 1000, 2);
			} else {
				// 非即时起保，起保时间是下一天0点
				result.start = this.$base.submitDateFormatter(Date.now() + 1 * 24 * 60 * 60 * 1000, 1);
				result.end = this.$base.submitDateFormatter(Date.now() + days * 24 * 60 * 60 * 1000, 2);
			}
			return result;
		},
	},
};
</script>
